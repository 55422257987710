<template>
  <div>
    <div class="usage-tracker">
      <div class="d-flex align-items-center">
        <div class="d-flex gap-2 justify-content-center w-100">
          <h3 class="tracker-title mb-0">Usage Tracker</h3>
          <div
            class="tooltip-container"
            @mouseenter="showTooltipHandler"
            @mouseleave="hideTooltipHandler"
          >
            <i class="ml-2 fas fa-question-circle"></i>
            <div
              class="tooltip-text"
              :class="{ show: showTooltip }"
              @mouseenter="showTooltipHandler"
              @mouseleave="hideTooltipHandler"
            >
              Individual subscriptions are now linked to usage. See our
              <a
                href="https://phoenixbioinformatics.atlassian.net/wiki/spaces/COM/pages/42217802/Individual+Subscription+FAQ"
                target="_blank"
                class="tooltip-link"
                >FAQ</a
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="trackerData != null" class="tracker-content">
        <div class="units-bar">
          <div class="units-label">
            <span>Remaining Units</span>
            <span class="units-value"
              >{{ trackerData.remaining_units }} /
              {{ trackerData.total_units }}</span
            >
          </div>
          <b-progress
            :value="usagePercentage"
            :variant="progressVariant"
          ></b-progress>
        </div>
        <div class="tracker-details">
          <div class="dates-section">
            <div v-if="formattedExpiryDate" class="date-item">
              <i class="fas fa-calendar-alt"></i>
              <span>Subscribed Units expiring: {{ formattedExpiryDate }}</span>
            </div>
            <div v-if="formattedFreeExpiryDate" class="date-item">
              <i class="fas fa-gift"></i>
              <span
                >50 Complimentary Units expiring:
                {{ formattedFreeExpiryDate }}</span
              >
            </div>
          </div>
          <div class="actions-section">
            <b-button
              variant="primary"
              class="buy-units-btn"
              :href="bucketPurchaseLink"
              target="_blank"
              >Add Usage Units</b-button
            >
            <div v-if="trackerData.bucketsBought" class="detail-item">
              <i class="fas fa-box"></i>
              <span>Bundles Bought: {{ trackerData.bucketsBought }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tracker-content">
        <div v-if="!isLoading" class="no-units-section">
          <div class="no-units-content">
            <i class="fas fa-box-open"></i>
            <h4>No Usage Units Available</h4>
            <p>Get started by adding usage units to your account</p>
            <b-button
              variant="primary"
              class="buy-units-btn"
              :href="bucketPurchaseLink"
              target="_blank"
              >Buy Usage Units</b-button
            >
          </div>
        </div>
        <div v-else class="loading-section">
          <b-spinner></b-spinner>
          <span>Loading Usage Tracker...</span>
        </div>
      </div>
    </div>
    <div>
      <div class="usage-tracker">
        <h3 class="tracker-title">Activation Code</h3>
        <b-button variant="secondary" @click="showActivation = !showActivation">
          {{ showActivation ? "Hide" : "Enter Activation Code" }}
        </b-button>
        <!-- New inline activation section -->
        <b-collapse
          id="activation-section"
          v-model="showActivation"
          class="mt-3"
        >
          <b-card>
            <h4>Redeem your activation code</h4>
            <b-form @submit.prevent="activateSubscription">
              <b-form-group>
                <b-form-input
                  id="activation-code"
                  v-model="activationCode"
                  :state="activationCodeState"
                  required
                  placeholder="Enter your activation code"
                ></b-form-input>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                :disabled="isActivating"
              >
                <b-spinner small v-if="isActivating"></b-spinner>
                {{ isActivating ? "Activating..." : "Activate" }}
              </b-button>
            </b-form>
            <b-alert v-if="activationError" show variant="danger" class="mt-3">
              {{ activationError }}
            </b-alert>
            <b-alert
              v-if="activationSuccess"
              show
              variant="primary"
              class="mt-3"
            >
              {{ activationSuccess }}
            </b-alert>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "UsageTracker",
  props: {
    trackerData: {
      type: [Object, null],
      default: null,
    },
    bucketPurchaseLink: {
      type: String,
      default: null,
    },
    partyId: {
      type: String,
      default: null,
    },
    onFetchTrackerData: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    usagePercentage() {
      if (this.trackerData.remaining_units == this.trackerData.total_units)
        return 100;
      return (
        (this.trackerData.remaining_units / this.trackerData.total_units) * 100
      );
    },
    progressVariant() {
      if (this.usagePercentage > 70) return "success";
      if (this.usagePercentage > 30) return "warning";
      return "danger";
    },
    formattedExpiryDate() {
      if (!this.trackerData.expiry_date) return null;
      return new Date(this.trackerData.expiry_date).toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      );
    },
    formattedFreeExpiryDate() {
      if (!this.trackerData.free_expiry_date) return null;
      let freeExpDate = new Date(this.trackerData.free_expiry_date);
      let regularExpDate = this.trackerData.expiry_date
        ? new Date(this.trackerData.expiry_date)
        : null;

      // If regular expiry date exists and is later than free expiry date, use regular expiry date
      if (regularExpDate && regularExpDate > freeExpDate) {
        freeExpDate = regularExpDate;
      }

      return freeExpDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    activationCodeState() {
      return this.activationCode.length > 0 ? null : false;
    },
  },
  data() {
    return {
      activationCode: "",
      showActivation: false,
      isActivating: false,
      activationError: null,
      activationSuccess: null,
      showTooltip: false,
      tooltipTimeout: null,
    };
  },
  methods: {
    showTooltipHandler() {
      this.showTooltip = true;
      clearTimeout(this.tooltipTimeout);
    },
    hideTooltipHandler() {
      this.tooltipTimeout = setTimeout(() => {
        this.showTooltip = false;
      }, 500); // Delay hiding the tooltip by 500ms
    },
    async activateSubscription() {
      if (!this.activationCode) {
        this.activationError = "Please enter a valid activation code.";
        return;
      }

      this.isActivating = true;
      this.activationError = null;

      try {
        if (!this.partyId) {
          this.activationError = "Error during activation.";
          console.error("partyId is not set properly");
        }
        const response = await Api().post("/subscription/activate/bucket", {
          activationCode: this.activationCode,
          partyId: this.partyId,
        });
        if (response.status && response.status == 200) {
          this.activationSuccess = "Activation Code applied successfully!";
          this.isActivating = false;
          this.activationError = "";
          this.activationCode = "";
          await this.onFetchTrackerData();
        } else {
          throw new Error(response.data.message || "Activation failed");
        }
      } catch (error) {
        // console.log(error);
        if (error.response) {
          this.activationError =
            error.response.data.message ||
            "An error occurred during activation.";
        } else {
          this.activationError =
            error.message ||
            "An error occurred while activating the subscription.";
        }
      } finally {
        this.isActivating = false;
      }
    },
  },
};
</script>

<style scoped>
.usage-tracker {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tracker-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.tracker-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.units-bar {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.units-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.units-value {
  color: var(--primary-color);
}

.tracker-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dates-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.date-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.95rem;
  color: #444;
}

.date-item i {
  color: var(--primary-color);
  width: 16px;
}

.date-item .fa-gift {
  color: #28a745;
}

.actions-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.buy-units-btn {
  color: white !important;
  min-width: 200px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: transform 0.2s ease;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.buy-units-btn:hover {
  transform: translateY(-2px);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 0.9;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  justify-content: center;
}

.detail-item i {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .date-item {
    font-size: 0.9rem;
  }
}

.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.tooltip-container i {
  color: var(--primary-color);
}

.tooltip-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  transition: opacity 0.3s, visibility 0.3s;
  pointer-events: none; /* Initially not accepting mouse events */
}

.tooltip-text.show {
  visibility: visible;
  opacity: 1;
  pointer-events: auto; /* Accept mouse events when visible */
}

.tooltip-link {
  color: #4caf50;
  text-decoration: underline;
  cursor: pointer;
}

.tooltip-link:hover {
  color: #45a049;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.no-units-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.no-units-content {
  text-align: center;
  padding: 2rem;
}

.no-units-content i {
  font-size: 3rem;
  color: #dc3545;
  margin-bottom: 1rem;
  opacity: 0.8;
}

.no-units-content h4 {
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.no-units-content p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.add-units-btn {
  color: white !important;
  min-width: 200px;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.add-units-btn:hover {
  transform: translateY(-2px);
}

.loading-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 1rem;
  color: #666;
}

.loading-section .spinner-border {
  width: 2rem;
  height: 2rem;
}
</style>
