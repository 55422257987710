<template>
  <div class="my-profile">
    <b-container v-if="!is_not_login">
      <b-row v-if="!isLoading">
        <b-col cols="12" md="8">
          <b-card class="mb-4">
            <h2 class="card-title">
              Welcome, {{ personData.FIRST_NAME }} {{ personData.LAST_NAME }}
            </h2>
            <div v-if="orcidFullId" class="align-center mb-3">
              <p class="mb-0 mr-3">Your ORCID ID: {{ orcidFullId }}</p>
              <b-button
                variant="outline-danger"
                size="sm"
                @click="handleUnlinkOrcid"
                :disabled="isUnlinking"
              >
                <span v-if="!isUnlinking">Unlink ORCID</span>
                <b-spinner small v-else></b-spinner>
              </b-button>
            </div>
            <p class="font-weight-bold" v-html="subscriber_info"></p>
            <p class="font-weight-bold">{{ subscriber_message }}</p>
          </b-card>

          <usage-tracker
            v-if="isShowTracker"
            :tracker-data="trackerData"
            :bucketPurchaseLink="bucketPurchaseLink"
            :partyId="partyId"
            :onFetchTrackerData="fetchTrackerData"
            :isLoading="isTrackerDataLoading"
            class="mb-4"
          />

          <b-card class="mb-4">
            <h3 class="card-title">Address</h3>
            <div v-html="getAddress()"></div>
          </b-card>

          <b-card>
            <h3 class="card-title">Affiliated Organizations</h3>
            <b-list-group
              v-if="
                personData.AFFILIATIONS && personData.AFFILIATIONS.length > 0
              "
            >
              <b-list-group-item
                v-for="(o, index) in personData.AFFILIATIONS"
                :key="index"
              >
                <b-link :href="`/organization?key=${o.community_id}`">{{
                  o.name
                }}</b-link>
              </b-list-group-item>
            </b-list-group>
            <span v-else>No affiliations found</span>
          </b-card>
        </b-col>

        <b-col cols="12" md="4">
          <b-card class="mb-4">
            <h3 class="card-title">Quick Links</h3>
            <b-list-group>
              <b-list-group-item :href="`/person?key=${communityId}`"
                >View my public profile</b-list-group-item
              >
              <b-list-group-item :href="`/edit/person?key=${communityId}`"
                >Update Profile</b-list-group-item
              >
              <b-list-group-item
                href="https://phoenixbioinformatics.atlassian.net/wiki/spaces/COM/pages/42217802/Individual+Subscription+FAQ"
                target="_blank"
                >Individual Subscription FAQ</b-list-group-item
              >
            </b-list-group>
          </b-card>

          <b-card class="mb-4" v-if="isSymbolRegEnabled">
            <h3 class="card-title">Contribute Data to TAIR</h3>
            <b-list-group>
              <b-list-group-item href="/submit/gene_class_symbol_registration"
                >Submit New Gene Class Symbol</b-list-group-item
              >
              <b-list-group-item href="https://goat.phoenixbioinformatics.org"
                >Online Submission for Authors and Others</b-list-group-item
              >
            </b-list-group>
          </b-card>

          <!-- Conditional rendering for TAIR Curator section -->
          <b-card v-if="personData.IS_TAIR_CURATOR === 'T'" class="mb-4">
            <h3 class="card-title">TAIR Curator Tools</h3>
            <b-list-group>
              <b-list-group-item
                href="https://arabidopsis.org/servlets/processor?type=genesymbol&update_action=view_symbol&from=curator&status=new"
                target="_blank"
              >
                Review Gene Class Symbols
              </b-list-group-item>
              <b-list-group-item href="/browse/gene_symbols"
                >Browse Gene Class Symbols</b-list-group-item
              >
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12" md="8">Loading ...</b-col>
      </b-row>
    </b-container>

    <b-alert v-else show variant="warning"
      >You are not logged in. Please login to view this page.</b-alert
    >
  </div>
</template>

<script>
import Api from "@/services/Api";
import { mapGetters } from "vuex";
import {
  getInstitutionByIP,
  getSubscriptionStatus,
} from "@/services/SubscriptionService";
import UsageTracker from "../components/UsageTracker.vue";
import { unlinkOrcid } from "@/services/orcidService";

export default {
  name: "Profile",
  components: {
    UsageTracker,
  },
  data() {
    return {
      trackerData: null,
      personData: {},
      is_not_login: false,
      isLoading: false,
      communityId: null,
      subscriber_message: "",
      isShowTracker: false,
      isSubscribed: false,
      isSymbolRegEnabled: false,
      orcidFullId: "",
      partyId: "",
      isTrackerDataLoading: false,
      subscriber_info: "",
      isUnlinking: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    subscribeLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/subscription?partnerId=tair&redirect=${process.env.VUE_APP_UI_BASE_URL}`;
    },
    bucketPurchaseLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/subscription/individual?partnerId=tair&redirect=${process.env.VUE_APP_UI_BASE_URL}&orcid_id=${this.orcidFullId}`;
    },
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getOrcidId: "authProfile/getOrcidId",
      getPartyId: "authProfile/getPartyId",
    }),
    getAddress() {
      let address = "";
      if (this.personData.ADDRESS) {
        address += this.personData.ADDRESS + "<br />";
      }
      if (this.personData.CITY) {
        address += this.personData.CITY + ", ";
      }
      if (this.personData.REGION) {
        address += this.personData.REGION + " - ";
      }
      if (this.personData.POSTAL_CODE) {
        address += this.personData.POSTAL_CODE + "<br />";
      }
      if (this.personData.COUNTRY) {
        address += this.personData.COUNTRY;
      }

      if (address == "") {
        address = "No address found";
      }
      return address;
    },
    async init() {
      this.isLoading = true;

      let communityId = this.getCommunityId();
      if (!communityId) {
        this.is_not_login = true;
        return;
      }
      this.communityId = communityId;
      let responseIP = await getInstitutionByIP();
      if (responseIP && responseIP.status) {
        if (responseIP.status == "not subscribed") {
          this.subscriber_info = `Basic TAIR Account`;
          await this.loadIndividualAccountInfo();
          this.subscriber_info += ` <br />Your institution: ${responseIP.name} is not subscribed to TAIR.`;
        } else {
          this.subscriber_info = `Institutional TAIR Account - includes full access to TAIR pages`;
          await this.fetchPersonData();
          await this.updateSubscriptionStatus();
          if (this.isSymbolRegEnabled) {
            this.subscriber_info += " and Gene Class Symbol registration.";
          } else {
            this.subscriber_info += " without Gene Class Symbol registration.";
          }
        }
      } else {
        this.subscriber_info = `Basic TAIR Account`;
        this.loadIndividualAccountInfo();
      }
      this.isLoading = false;
      this.isTrackerDataLoading = false;
    },
    async loadIndividualAccountInfo() {
      this.orcidFullId = this.getOrcidId();
      this.partyId = this.getPartyId();
      await this.fetchTrackerData();
      await this.fetchPersonData();
      if (this.isSymbolRegEnabled) {
        this.subscriber_info += " and Gene Class Symbol registration.";
      } else {
        this.subscriber_info += " without Gene Class Symbol registration.";
      }
    },
    async updateSubscriptionStatus() {
      let responseSub = await getSubscriptionStatus();
      if (responseSub.subscribed) {
        this.isSubscribed = true;
        let messages = [];

        // Handle regular subscription expiry date
        if (responseSub.expDate) {
          let expDate = new Date(responseSub.expDate);
          expDate = expDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          messages.push(`You're subscribed until ${expDate}`);
        }

        // Handle complimentary units expiry date
        if (responseSub.free_expiry_date) {
          let freeExpDate = new Date(responseSub.free_expiry_date);
          let regularExpDate = responseSub.expDate
            ? new Date(responseSub.expDate)
            : null;

          // If regular expiry date exists and is later than free expiry date, use regular expiry date
          if (regularExpDate && regularExpDate > freeExpDate) {
            freeExpDate = regularExpDate;
          }

          freeExpDate = freeExpDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          messages.push(`50 Complimentary Units expire on: ${freeExpDate}`);
        }

        this.subscriber_message = messages.join("<br />");
      } else {
        this.subscriber_message = "";
        this.isSubscribed = false;
      }
    },

    async fetchPersonData() {
      try {
        const response = await Api().get("/user/person-details", {
          params: { communityId: this.communityId },
        });
        if (response.data.message) {
          this.personData = null;
        } else {
          this.personData = response.data;
          this.isSymbolRegEnabled =
            this.personData.REGISTERING_FOR_ABRC === "T";
        }
      } catch (error) {
        console.error("Error fetching person data:", error);
      }
    },
    async fetchTrackerData() {
      try {
        this.isShowTracker = true;
        this.isTrackerDataLoading = true;
        const response = await Api().get("/subscription/bucket/usage", {
          params: { party_id: this.partyId },
        });
        //keep this for local testing
        // const response = {
        //   data: {
        //     user_usage_id: 2,
        //     partyId: 297215,
        //     partner_id: "",
        //     total_units: 50,
        //     remaining_units: 3,
        //     expiry_date: "2026-03-11T22:43:57.370272Z",
        //     free_expiry_date: "2026-03-12T22:43:57.370272Z",
        //   },
        // };
        if (response.data.message) {
          this.trackerData = null;
        } else {
          this.trackerData = response.data;
        }
        this.isTrackerDataLoading = false;
      } catch (error) {
        console.error("Error fetching tracker data:", error);
        this.isTrackerDataLoading = false;
      }
    },
    async handleUnlinkOrcid() {
      try {
        this.isUnlinking = true;

        // Show confirmation dialog
        const confirmed = await this.$bvModal.msgBoxConfirm(
          "Are you sure you want to unlink your ORCID account?",
          {
            title: "Confirm Unlink",
            okVariant: "danger",
            okTitle: "Unlink",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!confirmed) {
          return;
        }

        const response = await unlinkOrcid(
          // this.communityId,
          this.$store.state.authProfile.credentialId,
          this.$store.state.authProfile.secretKey
        );

        if (response.success) {
          // Clear ORCID ID from store and local state
          await this.$store.dispatch("authProfile/clearOrcidId");
          this.orcidFullId = "";

          // Show success message
          this.$bvToast.toast("ORCID account successfully unlinked", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        }
      } catch (error) {
        console.error("Error unlinking ORCID:", error);
        this.$bvToast.toast(
          "Failed to unlink ORCID account. Please try again.",
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isUnlinking = false;
      }
    },
  },
};
</script>

<style scoped>
.my-profile {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #333;
}

.my-profile {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem 0;
}

.card-title {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .my-profile {
    padding: 1rem 0;
  }
}
</style>
